.fieldset {
  width: 320px; // Equivalent to 'w-xs'
  background-color: #f3f3f3;
  border: 1px solid #d1d5db;
  padding: 1rem;
  border-radius: 6px;

  &-legend {
    font-weight: 600;
  }
}
