#commissionnement-session {
  thead {
    th:first-child {
      padding-left: 0 !important;
    }
  }
  tbody {
    td {
      &:first-child {
        min-width: 0 !important;
      }
      &.action-col {
        min-width: 85px !important;
      }
    }
  }
}

.datalist__filter {
  &.commissioning-filter {
    .form-control {
      grid-template-columns: 80px auto;
      margin-right: 15px;
    }
  }
  position: relative;
  z-index: 10;
}

.datalist__header {
  &.session-filters {
    .form-control {
      display: flex !important;
    }
  }
}

.form-bloc--action .react-datepicker__input-container {
  input {
    min-height: 35px;
    padding: 10px;
    border-radius: 5px;
    &:focus {
      outline-color:#2884f5fc;
    }
  }
}